module.exports = {
  activeOptions: [
    { value: 1, text: 'Active' },
    { value: 0, text: 'In active' },
  ],
  typeOptions: [
    { value: 1, text: 'Page main' },
    { value: 2, text: 'Page custom' },
  ],
}
